var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Expose } from 'class-transformer';
export class ModelUser {
    firstName;
    lastName;
    email;
    displayName;
    phoneNumber;
    token;
    imageUrl;
    balance;
    freePlay;
    isUnityLinked;
    totalUnityPoints;
    unityAccountNumber;
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "firstName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "lastName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "email", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "displayName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "phoneNumber", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "token", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "imageUrl", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], ModelUser.prototype, "balance", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], ModelUser.prototype, "freePlay", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Boolean)
], ModelUser.prototype, "isUnityLinked", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], ModelUser.prototype, "totalUnityPoints", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUser.prototype, "unityAccountNumber", void 0);
