var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Expose } from 'class-transformer';
export class ModelUnityDocument {
    consentActive;
    agreed;
    consentId;
    consentLanguage;
    consentLanguageIsoCode;
    consentLongName;
    consentName;
    consentVersion;
    consentVersionDateTime;
    consentContent;
}
__decorate([
    Expose(),
    __metadata("design:type", Boolean)
], ModelUnityDocument.prototype, "consentActive", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Boolean)
], ModelUnityDocument.prototype, "agreed", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentId", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentLanguage", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentLanguageIsoCode", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentLongName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentVersion", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], ModelUnityDocument.prototype, "consentVersionDateTime", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Object)
], ModelUnityDocument.prototype, "consentContent", void 0);
export class ModelUnityDocuments {
    documents;
}
__decorate([
    Expose(),
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], ModelUnityDocuments.prototype, "documents", void 0);
