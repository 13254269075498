import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import { PersonalInfo } from '~/views/organisms/personal.info';
import { AccountPayments } from '~/views/organisms/account.payments';
import { PurchaseHistory } from '~/views/organisms/purchase.history';
import { BackBtn } from '~/views/atoms/back.btn';
import { AccountMenu } from '~/views/molecules/account.menu';
import { AccountMenuItem } from '~/views/enums';
export const PageAccount = observer(() => {
    const location = useLocation();
    const menuItem = useMemo(() => {
        let accountMenuItem = AccountMenuItem.personalInfo;
        switch (location.pathname) {
            case ROUTES.PERSONAL_INFO.path:
                accountMenuItem = AccountMenuItem.personalInfo;
                break;
            case ROUTES.ACCOUNT_PAYMENTS.path:
                accountMenuItem = AccountMenuItem.payments;
                break;
            case ROUTES.PURCHASE_HISTORY.path:
                accountMenuItem = AccountMenuItem.purchaseHistory;
                break;
            default:
                accountMenuItem = AccountMenuItem.personalInfo;
                break;
        }
        return accountMenuItem;
    }, [location.pathname]);
    return (_jsxs("div", { className: "container", children: [_jsx(BackBtn, { path: ROUTES.MAIN.path }), _jsxs("div", { className: "account-wrap", children: [_jsx(AccountMenu, { activeItem: menuItem }), menuItem === AccountMenuItem.personalInfo ? _jsx(PersonalInfo, {}) : null, menuItem === AccountMenuItem.payments ? _jsx(AccountPayments, {}) : null, menuItem === AccountMenuItem.purchaseHistory ? _jsx(PurchaseHistory, {}) : null] })] }));
});
