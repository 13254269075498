import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';
import { useService } from '~/services/service.base';
import { WrapperService } from '~/services/service.wrapper';
export const UserTopMenu = observer((props) => {
    const wrapperService = useService(WrapperService);
    const { authService } = wrapperService;
    const [open, setOpen] = useState(false);
    const handleClickToggleMenu = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    const goToAccount = () => {
        handleClose();
        navigate(ROUTES.PERSONAL_INFO.path);
    };
    const logout = () => {
        handleClose();
        authService.logout();
        const privatePages = [
            ROUTES.PERSONAL_INFO.path, ROUTES.ACCOUNT_PAYMENTS.path
        ];
        if (privatePages.includes(location.pathname)) {
            navigate(ROUTES.MAIN.path);
        }
    };
    return (_jsxs("div", { className: "top-menu-wrap", children: [_jsx("div", { className: "username-wrap", children: authService.currentUser?.displayName }), _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsxs("div", { className: "user-avatar-wrap", onClick: handleClickToggleMenu, children: [_jsx("figure", { children: _jsx("img", { src: authService.currentUser?.imageUrl }) }), open && (_jsxs("ul", { className: "dropdown-menu", children: [_jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/letter-w-user-menu.svg" }) }) }), _jsxs("div", { className: "user-menu-text", children: ["W Dollars $", authService.currentUser?.balance] })] }) }), _jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/free-play-user-menu.svg" }) }) }), _jsxs("div", { className: "user-menu-text", children: ["Freeplay $", authService.currentUser?.freePlay] })] }) }), _jsx("li", { children: _jsxs("div", { className: "dropdown-item", children: [_jsx("div", { className: "user-menu-icon", children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/unity-user-menu.svg" }) }) }), _jsxs("div", { className: "user-menu-text", children: ["Unity Points ", authService.currentUser?.totalUnityPoints !== undefined && ('$'), authService.currentUser?.totalUnityPoints] })] }) }), _jsx("li", { children: _jsxs("div", { className: "user-menu-actions", children: [_jsx("button", { type: "button", className: "btn hr-blue-btn", onClick: goToAccount, children: "Account" }), _jsx("button", { type: "button", className: "btn hr-black-btn", onClick: logout, children: "Log out" })] }) })] }))] }) })] }));
});
