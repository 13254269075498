var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g;
import { BaseSingletonService, Singleton } from './service.base';
import { PaymentService } from '~/services/service.payment';
import { AccountService } from '~/services/service.account';
import { AddCreditCardService } from '~/services/service.add.credit.card';
import { AuthService } from '~/services/service.auth';
import { PromotionsService } from '~/services/service.promotions';
import { CodeVerificationService } from '~/services/service.code.verification';
import { PaysafeFormService } from '~/services/service.paysafe.form';
export let WrapperService = class WrapperService extends BaseSingletonService {
    paymentService;
    accountService;
    addCreditCardService;
    authService;
    promotionsService;
    codeVerificationService;
    paysafeFormService;
    constructor(paymentService, accountService, addCreditCardService, authService, promotionsService, codeVerificationService, paysafeFormService) {
        super();
        this.paymentService = paymentService;
        this.accountService = accountService;
        this.addCreditCardService = addCreditCardService;
        this.authService = authService;
        this.promotionsService = promotionsService;
        this.codeVerificationService = codeVerificationService;
        this.paysafeFormService = paysafeFormService;
    }
};
WrapperService = __decorate([
    Singleton(),
    __metadata("design:paramtypes", [typeof (_a = typeof PaymentService !== "undefined" && PaymentService) === "function" ? _a : Object, typeof (_b = typeof AccountService !== "undefined" && AccountService) === "function" ? _b : Object, typeof (_c = typeof AddCreditCardService !== "undefined" && AddCreditCardService) === "function" ? _c : Object, typeof (_d = typeof AuthService !== "undefined" && AuthService) === "function" ? _d : Object, typeof (_e = typeof PromotionsService !== "undefined" && PromotionsService) === "function" ? _e : Object, typeof (_f = typeof CodeVerificationService !== "undefined" && CodeVerificationService) === "function" ? _f : Object, typeof (_g = typeof PaysafeFormService !== "undefined" && PaysafeFormService) === "function" ? _g : Object])
], WrapperService);
