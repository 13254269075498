var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { api } from '~/tools/api/api';
import moment from 'moment';
import { EApiUrl } from '~/tools/api/enums';
import { ModelWdollarPackage } from '~/models/model.wdollar.package';
import { PaymentType } from '~/services/service.enums';
export class PaymentService {
    paymentMethod = PaymentType.creditCard;
    showSuccessBlock = false;
    showAllMode = true;
    slideArrowLeftEnabled = false;
    slideArrowRightEnabled = true;
    selectedWdollarPackage;
    paypalClientId = process.env.PAYPAL_CLIENT_ID ? process.env.PAYPAL_CLIENT_ID : '';
    wdollarPackages = observable.array([]);
    order;
    constructor() {
        makeObservable(this);
    }
    handleViewAll = () => {
        this.showAllMode = !this.showAllMode;
    };
    handleSwitchPayment = (e) => {
        e.preventDefault();
        this.paymentMethod = this.paymentMethod === PaymentType.creditCard ? PaymentType.paypal : PaymentType.creditCard;
    };
    setShowSuccessBlock = (value) => {
        this.showSuccessBlock = value;
    };
    setWdollarPackages = (wdollarPackages) => {
        // @ts-ignore
        this.wdollarPackages.replace(wdollarPackages);
    };
    getWdollarPackages = async (token) => {
        try {
            const response = await api.get(EApiUrl.wdollarPackages, {
                headers: {
                    Authorization: token
                }
            });
            if (response.status === 200) {
                let packages = [];
                for (const item of response.data.bundles[0].offers) {
                    const params = JSON.parse(item.items[0].parameters);
                    const freePlayParams = JSON.parse(item.items[1].parameters);
                    const price = +item.amount;
                    const discount = Math.round((+params.amount - price) / price * 100);
                    packages.push({
                        id: item.offerId,
                        price,
                        package: params.amount,
                        discountText: `Get +${discount}% Extra! $${freePlayParams.amount} Free Play!`,
                        ticket: response.data.ticket
                    });
                }
                ;
                packages.sort((a, b) => { return (+a.package > +b.package) ? 1 : -1; });
                const wdollarPackages = plainToInstance(ModelWdollarPackage, packages);
                this.setWdollarPackages(wdollarPackages);
            }
        }
        catch (error) { }
    };
    createOrder = async () => {
        try {
            /*const url: string = `${process.env.BACKEND_HOST}${EApiUrl.order}`;
            const response = await fetch(url, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                  cart: [{ id: '1', quantity: '1' }],
              }),
            });
            
            const orderData: any = await response.json();
            if (!orderData.id) {
              const errorDetail = orderData?.details[0];
              const errorMessage = errorDetail
                  ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                  : 'Unexpected error occurred, please try again.';
    
              throw new Error(errorMessage);
            }
    
            return orderData.id;*/
            const orderData = {
                id: '10'
            };
            if (!orderData.id) {
                const errorDetail = orderData?.details[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : 'Unexpected error occurred, please try again.';
                throw new Error(errorMessage);
            }
            return orderData.id;
        }
        catch (error) {
            console.error(error);
        }
    };
    paypalOnApprove = async (data) => {
        const url = `${process.env.BACKEND_HOST}${EApiUrl.order}`;
        // Capture the funds from the transaction.
        const response = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                orderID: data.orderID,
            }),
        });
        const details = await response.json();
    };
    handleSlideChange = (swiper) => {
        if (swiper.isBeginning) {
            this.slideArrowLeftEnabled = false;
        }
        else {
            this.slideArrowLeftEnabled = true;
        }
        if (swiper.isEnd) {
            this.slideArrowRightEnabled = false;
        }
        else {
            this.slideArrowRightEnabled = true;
        }
    };
    getWdollarPackage = (wdollarPackageId) => {
        this.selectedWdollarPackage = this.wdollarPackages.find(item => item.id === +wdollarPackageId);
    };
    onAfterPayment = async (paymentData, token) => {
        try {
            this.order = {
                id: paymentData.id,
                date: moment().utc().format('DD MMM, YYYY')
            };
            this.setShowSuccessBlock(true);
        }
        catch (error) { }
    };
    getPublicWdollarPackages = async () => {
        try {
            const response = await api.get(EApiUrl.publicWdollarPackages);
            if (response.status === 200) {
                let packages = [];
                for (const item of response.data.bundles[0].offers) {
                    const params = JSON.parse(item.items[0].parameters);
                    packages.push({
                        id: item.offer_id,
                        price: null,
                        package: params.amount,
                        discountText: 'Get Much More! + Free Play!'
                    });
                }
                ;
                packages.sort((a, b) => { return (+a.package > +b.package) ? 1 : -1; });
                const wdollarPackages = plainToInstance(ModelWdollarPackage, packages);
                this.setWdollarPackages(wdollarPackages);
            }
        }
        catch (error) { }
    };
}
__decorate([
    observable,
    __metadata("design:type", String)
], PaymentService.prototype, "paymentMethod", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "showSuccessBlock", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "showAllMode", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "slideArrowLeftEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PaymentService.prototype, "slideArrowRightEnabled", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof ModelWdollarPackage !== "undefined" && ModelWdollarPackage) === "function" ? _a : Object)
], PaymentService.prototype, "selectedWdollarPackage", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleViewAll", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleSwitchPayment", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "setShowSuccessBlock", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "setWdollarPackages", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "getWdollarPackages", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "handleSlideChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "getWdollarPackage", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PaymentService.prototype, "getPublicWdollarPackages", void 0);
