var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { ModelPromotion } from '~/models/model.promotion';
export class PromotionsService {
    promotions = observable.array([]);
    constructor() {
        makeObservable(this);
        this.getPromotions();
    }
    getPromotions = async () => {
        try {
            /*const response: AxiosResponse = await api.get<ModelPromotion[]>(EApiUrl.promotion);
            if (response.statusText?.toLowerCase() === 'ok') {
              const promotions = plainToInstance(ModelPromotion, response.data);
              this.setPromotions(promotions);
            }*/
            const data = [
                {
                    title: 'Play Mobile and Online Games That Rock!',
                    text: 'Our legendary lineup of games are ready to rock your world – whether you’re into Puzzle Games or Free Casinos, our portfolio has something for you!',
                    image: 'https://wgames.webmind28.com/assets/img/promo-main.png',
                    btnLink: 'https://wgames.webmind28.com/assets/img/promo-main.png'
                }
            ];
            const promotions = plainToInstance(ModelPromotion, data);
            this.setPromotions(promotions);
        }
        catch (error) { }
    };
    setPromotions = (promotions) => {
        // @ts-ignore
        this.promotions.replace(promotions);
    };
}
__decorate([
    action,
    __metadata("design:type", Object)
], PromotionsService.prototype, "getPromotions", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PromotionsService.prototype, "setPromotions", void 0);
