import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useSwiper } from 'swiper/react';
import { useService } from '~/services/service.base';
import { DialogService } from '~/services/service.dialog';
export const SwiperNavigations = observer(() => {
    const swiper = useSwiper();
    const dialogService = useService(DialogService);
    const { redeemDialogSrv } = dialogService;
    return (_jsxs("div", { className: "redeem-amounts-arrows", children: [_jsx("div", { className: "redeem-amounts-arrow-left", children: _jsx("a", { className: "redeem-slide-prev", onClick: (e) => { e.preventDefault(); swiper.slidePrev(); }, href: "#", children: _jsxs("figure", { children: [redeemDialogSrv.slideArrowLeftEnabled && (_jsx("img", { id: "redeem-arrow-left", src: "/assets/img/redeem-arrow-left.svg" })), !redeemDialogSrv.slideArrowLeftEnabled && (_jsx("img", { id: "redeem-arrow-left-disabled", src: "/assets/img/redeem-arrow-left-disabled.svg" }))] }) }) }), _jsx("div", { className: "redeem-amounts-arrow-right", children: _jsx("a", { className: "redeem-slide-next", href: "#", onClick: (e) => { e.preventDefault(); swiper.slideNext(); }, children: _jsxs("figure", { children: [redeemDialogSrv.slideArrowRightEnabled && (_jsx("img", { id: "redeem-arrow-right", src: "/assets/img/redeem-arrow-right.svg" })), !redeemDialogSrv.slideArrowRightEnabled && (_jsx("img", { id: "redeem-arrow-right-disabled", src: "/assets/img/redeem-arrow-right-disabled.svg" }))] }) }) })] }));
});
