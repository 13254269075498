import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from '@mui/material/styles';
import { AppRouter } from './routes/router';
import { AppContainerProvider } from './services/service.base';
import { theme } from './theme/default';
import { QueryClient, QueryClientProvider } from 'react-query';
export const App = () => {
    const queryClient = new QueryClient();
    return (_jsx(AppContainerProvider, { children: _jsx(ThemeProvider, { theme: theme, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(AppRouter, {}) }) }) }));
};
