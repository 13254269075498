var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Expose } from 'class-transformer';
class UnityUserStatus {
    accountNumber;
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], UnityUserStatus.prototype, "accountNumber", void 0);
class UnityUserSummary {
    accountNumber;
    emailAddress;
    firstName;
    lastName;
    totalTierCredits;
    totalUnityPoints;
}
__decorate([
    Expose(),
    __metadata("design:type", String)
], UnityUserSummary.prototype, "accountNumber", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], UnityUserSummary.prototype, "emailAddress", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], UnityUserSummary.prototype, "firstName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", String)
], UnityUserSummary.prototype, "lastName", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], UnityUserSummary.prototype, "totalTierCredits", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Number)
], UnityUserSummary.prototype, "totalUnityPoints", void 0);
export class ModelUnityUser {
    status;
    summary;
    unityPointIsActive;
}
__decorate([
    Expose(),
    __metadata("design:type", UnityUserStatus)
], ModelUnityUser.prototype, "status", void 0);
__decorate([
    Expose(),
    __metadata("design:type", UnityUserSummary)
], ModelUnityUser.prototype, "summary", void 0);
__decorate([
    Expose(),
    __metadata("design:type", Boolean)
], ModelUnityUser.prototype, "unityPointIsActive", void 0);
