import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import { PageHeader } from '~/views/organisms/page.header';
import { PageFooter } from '~/views/organisms/page.footer';
const Main = styled('main')({});
export const AppWrapper = observer(() => {
    return (_jsxs(Fragment, { children: [_jsx(PageHeader, {}), _jsx(Main, { children: _jsx(Outlet, {}) }), _jsx(PageFooter, {})] }));
});
