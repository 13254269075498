import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { ROUTES } from '~/routes/routes';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { DropdownArrow } from '~/views/atoms/svg-icons/dropdown-arrow';
import { AccountMenuItem } from '~/views/enums';
export const AccountMenu = observer((props) => {
    let selectedPage = props.activeItem;
    const navigate = useNavigate();
    const [mobileSelectedPage, setMobileSelectedPage] = useState(selectedPage);
    const onAccountPageChange = useCallback((event) => {
        const menuItemValue = event.target.value;
        setMobileSelectedPage(menuItemValue);
        let path = ROUTES.PERSONAL_INFO.path;
        switch (menuItemValue) {
            case AccountMenuItem.personalInfo:
                path = ROUTES.PERSONAL_INFO.path;
                break;
            case AccountMenuItem.payments:
                path = ROUTES.ACCOUNT_PAYMENTS.path;
                break;
            case AccountMenuItem.purchaseHistory:
                path = ROUTES.PURCHASE_HISTORY.path;
                break;
            default:
                path = ROUTES.PERSONAL_INFO.path;
                break;
        }
        navigate(path);
    }, [navigate, setMobileSelectedPage]);
    const menuItems = [
        {
            title: 'Personal info',
            link: ROUTES.PERSONAL_INFO.path,
            className: selectedPage === AccountMenuItem.personalInfo ? 'account-active' : '',
            imgSrc: selectedPage === AccountMenuItem.personalInfo ? '/assets/img/personal-info-active.svg' : '/assets/img/personal-info.svg',
            mobileTitle: 'Personal info',
            mobileValue: AccountMenuItem.personalInfo
        },
        {
            title: 'Payments',
            link: ROUTES.ACCOUNT_PAYMENTS.path,
            className: selectedPage === AccountMenuItem.payments ? 'account-active' : '',
            imgSrc: selectedPage === AccountMenuItem.payments ? '/assets/img/account-payments-active.svg' : '/assets/img/account-payments.svg',
            mobileTitle: 'Payment methods',
            mobileValue: AccountMenuItem.payments
        },
        {
            title: 'Purchase History',
            link: ROUTES.PURCHASE_HISTORY.path,
            className: selectedPage === AccountMenuItem.purchaseHistory ? 'account-active' : '',
            imgSrc: selectedPage === AccountMenuItem.payments ? '/assets/img/purchase-history-active.svg' : '/assets/img/purchase-history.svg',
            mobileTitle: 'Purchase History',
            mobileValue: AccountMenuItem.purchaseHistory
        }
    ];
    return (_jsxs("div", { className: "account-menu" + (selectedPage === AccountMenuItem.purchaseHistory ? " account-menu-ph" : ""), children: [_jsx("div", { className: "account-menu-inner", children: menuItems.map((item, index) => (_jsxs("div", { className: "account-menu-item", children: [_jsx("figure", { children: _jsx("img", { src: item.imgSrc }) }), _jsx(Link, { className: item.className, to: item.link, children: item.title })] }, index))) }), _jsx("div", { className: "account-menu-select", children: _jsx(Select, { className: "hr-select", IconComponent: DropdownArrow, value: mobileSelectedPage, onChange: onAccountPageChange, children: menuItems.map((item, index) => (_jsx(MenuItem, { value: item.mobileValue, children: item.mobileTitle }, index))) }) })] }));
});
