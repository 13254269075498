var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import parse from 'html-react-parser';
import { UnityDocumentType } from '~/views/enums';
export let PrivacyPolicyDialogService = class PrivacyPolicyDialogService {
    open = false;
    documentText;
    constructor() {
        makeObservable(this);
    }
    handleOpen = (e, unityDocuments, documentType) => {
        e.preventDefault();
        this.open = true;
        let document;
        switch (documentType) {
            case UnityDocumentType.privacy:
                document = unityDocuments.documents.find(item => item.consentName === 'Unity Privacy Policy');
                break;
            case UnityDocumentType.terms:
                document = unityDocuments.documents.find(item => item.consentName === 'Unity Terms and Conditions');
                break;
        }
        if (document) {
            this.documentText = parse(document.consentContent);
        }
    };
    handleClose = () => {
        this.open = false;
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], PrivacyPolicyDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Object)
], PrivacyPolicyDialogService.prototype, "documentText", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PrivacyPolicyDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], PrivacyPolicyDialogService.prototype, "handleClose", void 0);
PrivacyPolicyDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], PrivacyPolicyDialogService);
