import { jsx as _jsx } from "react/jsx-runtime";
import 'reflect-metadata';
import './styles/index.scss';
import { configure } from 'mobx';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './app';
configure({ useProxies: 'never' });
const root = createRoot(document.getElementById('root'));
root.render(_jsx(StrictMode, { children: _jsx(App, {}) }));
