import axios from 'axios';
export const getBaseUrl = (unityUrl) => {
    return unityUrl ? process.env.UNITY_BACKEND_HOST : process.env.BACKEND_HOST;
};
export const getUnityBaseUrl = () => {
    return process.env.UNITY_BACKEND_HOST;
};
export const api = {
    get: (url, params, unityUrl) => axios.get(url, {
        baseURL: getBaseUrl(unityUrl),
        ...params
    }),
    post: (url, data, params, unityUrl) => axios.post(url, data, {
        baseURL: getBaseUrl(unityUrl),
        ...params
    }),
    patch: (url, data) => axios.patch(url, data, {
        baseURL: getBaseUrl(),
    }),
    put: (url, data, params) => axios.put(url, data, {
        baseURL: getBaseUrl(),
        ...params
    }),
    delete: (url, params) => axios.delete(url, {
        baseURL: getBaseUrl(),
        ...params
    }),
};
