var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Injectable } from './service.base';
import { action, makeObservable, observable } from 'mobx';
import { plainToInstance } from 'class-transformer';
import { ModelCreditCard } from '~/models/model.credit.card';
export let AddCreditCardService = class AddCreditCardService {
    cardNumberValid = false;
    cardNumberError = false;
    expiryDateError = false;
    cvcError = false;
    btnDisabled = true;
    cardSaveOptionChecked = true;
    showLoading = false;
    cardHolder;
    touchedFields = [];
    creditCard = {
        cardHolder: '',
        cardNumber: '',
        cardCvc: ''
    };
    constructor() {
        makeObservable(this);
    }
    onCardNumberBlur = (cardType, erroredInputs, event) => {
        if (cardType && !this.cardNumberError) {
            this.cardNumberValid = true;
            this.creditCard.cardNumber = event.target.value;
            this.creditCard.cardType = cardType.displayName;
        }
        else {
            this.cardNumberValid = false;
        }
        this.setTouchedFields('card_number');
        this.validateAllFields();
    };
    onCardNumberError = (cardNumberError) => {
        if (cardNumberError) {
            this.cardNumberError = true;
            this.cardNumberValid = false;
        }
    };
    onCardNumberFocus = () => {
        this.cardNumberValid = false;
        this.cardNumberError = false;
    };
    onExpiryDateBlur = (event) => {
        if (event.target.value) {
            const dateArr = event.target.value.split('/');
            if (dateArr && dateArr.length === 2) {
                const month = dateArr[0].trim();
                const shortYear = dateArr[1].trim();
                this.creditCard.cardExpiration = `${month}/20${shortYear}`;
            }
        }
        this.setTouchedFields('expiry_date');
        this.validateAllFields();
    };
    onExpiryDateError = (expiryDateError) => {
        if (expiryDateError) {
            this.expiryDateError = true;
        }
    };
    onExpiryDateFocus = () => {
        this.expiryDateError = false;
    };
    onCvcBlur = (event) => {
        if (event.target.value) {
            this.creditCard.cardCvc = event.target.value;
        }
        this.setTouchedFields('cvc');
        this.validateAllFields();
    };
    onCvcError = (cvcError) => {
        if (cvcError) {
            this.cvcError = true;
        }
    };
    onCvcFocus = () => {
        this.cvcError = false;
    };
    validateAllFields = () => {
        this.btnDisabled = this.touchedFields.length < 3 || this.cardNumberError || this.expiryDateError || this.cvcError;
    };
    setTouchedFields = (fieldName) => {
        if (!this.touchedFields.includes(fieldName)) {
            this.touchedFields.push(fieldName);
        }
    };
    handleCardSaveOptionChange = () => {
        this.cardSaveOptionChecked = !this.cardSaveOptionChecked;
    };
    onCardHolderBlur = (event) => {
        if (event.target.value) {
            this.creditCard.cardHolder = event.target.value;
        }
    };
    setShowLoading = (value) => {
        this.showLoading = value;
    };
    setCardNumberValid = (value) => {
        this.cardNumberValid = value;
    };
    setCardHolder = (value) => {
        this.creditCard.cardHolder = value;
    };
    setCardNumber = (value) => {
        this.creditCard.cardNumber = value;
    };
    setCardCvc = (value) => {
        this.creditCard.cardCvc = value;
    };
    handleBtnClick = async (onAfterAddCreditCard, currentUser) => {
        if (!this.btnDisabled && this.cardSaveOptionChecked) {
            try {
                this.setShowLoading(true);
                /*const response: AxiosResponse = await api.post(EApiUrl.creditCard, this.creditCard, {
                  headers: {
                    Authorization: `Bearer ${currentUser.token}`
                  }
                });
                if (response.statusText?.toLowerCase() === 'ok') {
                  if (response.data?.id) {
                    const newCreditCard = plainToInstance(ModelCreditCard, response.data);
                    onAfterAddCreditCard([newCreditCard]);
                    this.setCardHolder('');
                    this.setCardNumber('');
                    this.setCardCvc('');
      
                    this.setCardNumberValid(false);
                    this.setShowLoading(false);
                  }
                }*/
                const data = this.creditCard;
                const newCreditCard = plainToInstance(ModelCreditCard, data);
                onAfterAddCreditCard([newCreditCard]);
                this.setCardHolder('');
                this.setCardNumber('');
                this.setCardCvc('');
                this.setCardNumberValid(false);
                this.setShowLoading(false);
            }
            catch (error) { }
        }
        else if (!this.btnDisabled) {
            onAfterAddCreditCard([{ ...this.creditCard }]);
            this.setCardHolder('');
            this.setCardNumber('');
            this.setCardCvc('');
            this.setCardNumberValid(false);
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardNumberValid", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardNumberError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "expiryDateError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cvcError", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "cardSaveOptionChecked", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], AddCreditCardService.prototype, "showLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], AddCreditCardService.prototype, "cardHolder", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof ModelCreditCard !== "undefined" && ModelCreditCard) === "function" ? _a : Object)
], AddCreditCardService.prototype, "creditCard", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardNumberFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onExpiryDateFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCvcFocus", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "validateAllFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setTouchedFields", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "handleCardSaveOptionChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "onCardHolderBlur", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setCardNumberValid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setCardHolder", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setCardNumber", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "setCardCvc", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], AddCreditCardService.prototype, "handleBtnClick", void 0);
AddCreditCardService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], AddCreditCardService);
