export var AccountMenuItem;
(function (AccountMenuItem) {
    AccountMenuItem["personalInfo"] = "personal-info";
    AccountMenuItem["payments"] = "payments";
    AccountMenuItem["purchaseHistory"] = "purchase-history";
})(AccountMenuItem || (AccountMenuItem = {}));
export var SupportMenuItem;
(function (SupportMenuItem) {
    SupportMenuItem["support"] = "support";
    SupportMenuItem["messages"] = "messages";
})(SupportMenuItem || (SupportMenuItem = {}));
export var UnityDocumentType;
(function (UnityDocumentType) {
    UnityDocumentType["privacy"] = "privacy";
    UnityDocumentType["terms"] = "terms";
})(UnityDocumentType || (UnityDocumentType = {}));
