import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import * as di from 'tsyringe';
export const Singleton = di.singleton;
export const Inject = di.inject;
export const Injectable = di.injectable;
export const DelayInject = (token) => di.inject(di.delay(token));
export const useService = (...svcs) => {
    const app = useContext(ctx);
    return svcs.length === 1 ? app.resolve(svcs[0]) : svcs.map(svc => app.resolve(svc));
};
export const AppContainerProvider = ({ children }) => {
    return _jsx(ctx.Provider, { value: di.container, children: children });
};
export class BaseService {
    log = (...args) => {
        console.log(this.constructor.name, ...args);
    };
    get self() {
        return this;
    }
}
export class BaseSingletonService extends BaseService {
    constructor() {
        super();
        const ctor = this.constructor;
        if (singlestones.has(ctor)) {
            console.warn('Duplicate service created: ' + ctor.name);
        }
        singlestones.add(ctor);
    }
}
const singlestones = new Set();
const ctx = createContext(null);
