import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useService } from '~/services/service.base';
import { DialogService } from '~/services/service.dialog';
export const PrivacyPolicyDialog = observer(() => {
    const dialogService = useService(DialogService);
    const { privacyPolicyDialogSrv } = dialogService;
    return (_jsx(Dialog, { open: privacyPolicyDialogSrv.open, className: "hr-modal-dialog link-unity-modal", children: _jsx("div", { className: "modal-dialog modal-dialog-centered privacy-policy", children: _jsxs("div", { className: "modal-content", children: [_jsx("div", { className: "modal-header", children: _jsx("button", { type: "button", className: "close", onClick: privacyPolicyDialogSrv.handleClose, children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/modal-close-btn.svg" }) }) }) }), _jsx("div", { className: "modal-body", children: _jsxs("div", { className: "modalContent", children: [_jsx("div", { className: "unity-logo-wrap-modal", children: _jsxs("figure", { children: [_jsx("img", { className: "unity-logo-modal", src: "/assets/img/unity-logo-modal.svg" }), _jsx("img", { className: "unity-logo-modal-mobile", src: "/assets/img/unity-logo-modal-mobile.svg" })] }) }), _jsx("div", { className: "privacy-policy-content", children: privacyPolicyDialogSrv.documentText })] }) })] }) }) }));
});
