import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useService } from '~/services/service.base';
import { DialogService } from '~/services/service.dialog';
import { CodeVerification } from '~/views/molecules/code.verification';
export const VerificationCodeDialog = observer(({ onSendVerificationCode, onEnterVerificationCode }) => {
    const dialogService = useService(DialogService);
    const { verificationCodeDialogSrv } = dialogService;
    return (_jsx(Dialog, { open: verificationCodeDialogSrv.open, className: "hr-modal-dialog", children: _jsx("div", { id: "sign-in-modal", className: "modal-dialog modal-dialog-centered", children: _jsxs("div", { className: "modal-content", children: [_jsx("div", { className: "modal-header", children: _jsx("button", { type: "button", className: "close", onClick: verificationCodeDialogSrv.handleClose, children: _jsx("figure", { children: _jsx("img", { src: "/assets/img/modal-close-btn.svg" }) }) }) }), _jsx("div", { className: "modal-body", children: _jsx("div", { className: "modalContent step3", children: _jsxs(CodeVerification, { onSendVerificationCode: onSendVerificationCode, onEnterVerificationCode: onEnterVerificationCode, children: ["We\u2019ve sent a 6-digit code to the email", _jsx("br", {}), "linked to your Unity account."] }) }) })] }) }) }));
});
