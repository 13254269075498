export var EApiUrl;
(function (EApiUrl) {
    EApiUrl["unitySignIn"] = "go-rewards-client-service/api/unanimity/signin";
    EApiUrl["unitySignUp"] = "go-rewards-client-service/api/unanimity/signup";
    EApiUrl["unityOtp"] = "go-rewards-client-service/api/unanimity/otp";
    EApiUrl["unityUserSummary"] = "go-rewards-client-service/api/unanimity/summary";
    EApiUrl["unityDocuments"] = "go-rewards-client-service/api/unanimity/consent-document";
    EApiUrl["requestSmsCode"] = "go-webshop/api/v1/auth/request-sms-code";
    EApiUrl["verifySmsCode"] = "go-webshop/api/v1/auth/verify-sms-code";
    EApiUrl["profile"] = "go-webshop/api/v1/profile";
    EApiUrl["userBalance"] = "go-webshop/api/v1/profile/balance";
    EApiUrl["wdollarPackages"] = "go-webshop/api/v1/shop/offers";
    EApiUrl["publicWdollarPackages"] = "go-webshop/api/v1/public/shop/offers";
    EApiUrl["createPaysafeOrder"] = "go-webshop/api/v1/shop/create-order";
    EApiUrl["completePaysafeOrder"] = "go-webshop/api/v1/shop/complete-order";
    EApiUrl["order"] = "go-wdollar-portal/api/orders";
    EApiUrl["ordersByCreditCard"] = "go-wdollar-portal/api/orders-by-credit-card";
    EApiUrl["countries"] = "go-wdollar-portal/api/countries";
    EApiUrl["creditCard"] = "go-wdollar-portal/api/credit-card";
    EApiUrl["redeemAmounts"] = "go-wdollar-portal/api/redeem-amounts";
    EApiUrl["redeemLocations"] = "go-wdollar-portal/api/redeem-locations";
    EApiUrl["redeem"] = "go-wdollar-portal/api/redeem";
    EApiUrl["promotion"] = "go-wdollar-portal/api/promotion";
})(EApiUrl || (EApiUrl = {}));
