import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useService } from '~/services/service.base';
import { AddCreditCard } from '~/views/molecules/add.credit.card';
import { WrapperService } from '~/services/service.wrapper';
export const AccountPayments = observer(() => {
    const wrapperService = useService(WrapperService);
    const { accountService, authService } = wrapperService;
    const onAfterAddCreditCard = (creditCards) => {
        accountService.setCreditCards(creditCards);
    };
    accountService.getUserCreditCards(authService.currentUser);
    return (_jsx("div", { className: "account-content", children: _jsxs("div", { className: "account-content-inner", children: [_jsx("h3", { children: "Payment methods" }), accountService.creditCards.map((creditCard) => (_jsxs("div", { className: "credit-card-wrap", children: [_jsx("h4", { children: "Credit card" }), _jsxs("div", { className: "credit-card", children: [_jsxs("div", { className: "credit-card-data", children: [_jsxs("p", { className: "credit-card-number", children: [creditCard.cardType, " ", creditCard.cardNumber ? creditCard.cardNumber.replaceAll(/\*/ig, '•') : ''] }), _jsxs("p", { className: "credit-card-expiration", children: ["Expiration: ", creditCard.cardExpiration] })] }), _jsx("div", { className: "credit-card-btn-wrap", children: _jsx("button", { type: "button", className: "btn", onClick: () => accountService.handleRemoveCardClick(creditCard.id, authService.currentUser), children: "Remove Card" }) })] })] }, creditCard.id))), _jsx(AddCreditCard, { showSaveCard: false, onAfterAddCreditCard: onAfterAddCreditCard, btnWrapClass: "add-payment-btn-wrap", btnText: "Add payment method" })] }) }));
});
