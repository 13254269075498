var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { action, makeObservable, observable } from 'mobx';
import { Injectable } from './service.base';
import { useRefs } from '~/tools/refs';
import Cookies from 'js-cookie';
export let CodeVerificationService = class CodeVerificationService {
    isCodeExpired = false;
    codeResendForbidden = false;
    isCodeInvalid = false;
    codeLeftSeconds = 0;
    resendCodeInvalidTimes = 0;
    verificationCode = ['', '', '', '', '', ''];
    verificationCodeInputs = [0, 1, 2, 3, 4, 5];
    verificationCodeRefs;
    constructor() {
        makeObservable(this);
        this.verificationCodeRefs = useRefs(this.verificationCodeInputs.length, null);
    }
    setCodeExpired = (value) => {
        this.isCodeExpired = value;
    };
    onVerificationCodeChange = (value, index) => {
        this.setCodeExpired(false);
        this.verificationCode[index] = value;
    };
    resendTimer = (seconds) => {
        this.codeLeftSeconds = seconds;
        this.setCodeExpired(false);
        const interval = setInterval(() => {
            if (this.codeLeftSeconds > 0) {
                this.decrementCodeLeftSeconds();
            }
            else {
                clearInterval(interval);
                this.setCodeExpired(true);
                if (this.resendCodeInvalidTimes > 4) {
                    const expMinutes = 10;
                    const date = new Date();
                    date.setTime(date.getTime() + (expMinutes * 60 * 1000));
                    Cookies.set('delayResendCookie', 'Yes', { path: '/', expires: date });
                    this.setCodeResendForbidden(true);
                }
            }
        }, 1000);
    };
    decrementCodeLeftSeconds = () => {
        this.codeLeftSeconds--;
    };
    setCodeResendForbidden = (value) => {
        this.codeResendForbidden = value;
    };
    setCodeLeftSeconds = (value) => {
        this.codeLeftSeconds = value;
    };
    onVerificationCodeKeyUp = async (e, index, onEnterVerificationCode) => {
        if (e.keyCode === 8) {
            this.removePreviousDigit(index);
            return;
        }
        else if (e.keyCode === 37 || e.keyCode === 39) {
            this.onVerificationCodeArrowPress(e.keyCode, index);
            return;
        }
        else if (((e.ctrlKey === true || e.metaKey === true) && e.keyCode === 86)
            || e.keyCode === 17 || e.keyCode === 46) {
            return;
        }
        if (this.verificationCode[index] && this.verificationCode[index].length > 1) {
            this.insertMultipleValues(index, this.verificationCode[index]);
            return;
        }
        if (index < (this.verificationCodeInputs.length - 1)) {
            if (this.verificationCode[index] && /^[0-9]+$/i.test(this.verificationCode[index])) {
                index++;
                this.verificationCodeRefs[index].current.focus();
            }
        }
        else if (this.verificationCode[index] && /^[0-9]+$/i.test(this.verificationCode[index])) {
            this.verificationCodeRefs[index].current.blur();
            const verificationCode = this.verificationCode.join('');
            if (!/^[0-9]+$/i.test(verificationCode)) {
                this.setCodeInvalid(true);
            }
            else {
                this.setCodeInvalid(false);
            }
            if (!this.isCodeInvalid) {
                const verifyCodeResult = await onEnterVerificationCode(verificationCode);
                if (!verifyCodeResult) {
                    this.setCodeInvalid(true);
                    this.setCodeLeftSeconds(0);
                }
            }
        }
    };
    insertMultipleValues = (index, digits) => {
        const digitsArr = digits.split('');
        for (const number of digitsArr) {
            if (index < 6) {
                this.verificationCode[index] = number;
            }
            index++;
        }
        ;
        if (index < 6) {
            this.verificationCodeRefs[index].current.focus();
        }
    };
    onVerificationCodeKeyDown = (e) => {
        const arrowsKeyCodes = [37, 39, 40];
        const numPadNumberKeyCodes = [96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
        const acv = [65, 67, 86];
        const tabBackDel = [8, 9, 46];
        const specialChars = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '.'];
        const macProblems = [86, 91, 93];
        if (((e.keyCode < 48 &&
            !arrowsKeyCodes.includes(e.keyCode)) || (e.keyCode > 57 &&
            !numPadNumberKeyCodes.includes(e.keyCode)))
            && !tabBackDel.includes(e.keyCode) && (e.ctrlKey === false
            || ((e.ctrlKey === true || e.metaKey === true) && !acv.includes(e.keyCode)))) {
            if (macProblems.includes(e.keyCode)) {
                return;
            }
            e.preventDefault();
        }
        if (specialChars.includes(e.key)) {
            e.preventDefault();
        }
    };
    onVerificationCodePaste = async (e, onEnterVerificationCode) => {
        e.preventDefault();
        const pasteText = e.clipboardData.getData('Text');
        if (/^[0-9]+$/i.test(pasteText)) {
            const pasteTextArr = pasteText.split('');
            let index = 0;
            for (const number of pasteTextArr) {
                if (index < 6) {
                    this.verificationCode[index] = number;
                }
                index++;
            }
            ;
            if (pasteTextArr.length > 5) {
                this.verificationCodeRefs[5].current.focus();
                this.verificationCodeRefs[5].current.blur();
                const verificationCode = this.verificationCode.join('');
                const verifyCodeResult = await onEnterVerificationCode(verificationCode);
                if (!verifyCodeResult) {
                    this.setCodeInvalid(true);
                    this.setCodeLeftSeconds(0);
                }
            }
        }
    };
    removePreviousDigit = (index) => {
        index--;
        if (index < 0) {
            return;
        }
        this.verificationCodeRefs[index].current.focus();
        this.verificationCode[index] = '';
    };
    onVerificationCodeArrowPress = (keyCode, index) => {
        const targetIndex = keyCode === 37 ? index - 1 : index + 1;
        if (targetIndex < 0 || targetIndex > 5) {
            return;
        }
        this.verificationCodeRefs[targetIndex].current.focus();
    };
    setCodeInvalid = (value) => {
        this.isCodeInvalid = value;
    };
    handleResendClick = async (e, onSendVerificationCode) => {
        e.preventDefault();
        const resendResult = await onSendVerificationCode();
        if (resendResult) {
            this.initSendVerificationCode();
        }
    };
    initSendVerificationCode = () => {
        this.setCodeInvalid(false);
        this.verificationCode = ['', '', '', '', '', ''];
        this.verificationCodeRefs[0].current.focus();
        const delayResendCookie = Cookies.get('delayResendCookie');
        if (!delayResendCookie) {
            this.resendTimer(120);
            this.resendCodeInvalidTimes++;
        }
        else {
            this.setCodeResendForbidden(true);
        }
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CodeVerificationService.prototype, "isCodeExpired", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CodeVerificationService.prototype, "codeResendForbidden", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], CodeVerificationService.prototype, "isCodeInvalid", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CodeVerificationService.prototype, "codeLeftSeconds", void 0);
__decorate([
    observable,
    __metadata("design:type", Number)
], CodeVerificationService.prototype, "resendCodeInvalidTimes", void 0);
__decorate([
    observable,
    __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object)
], CodeVerificationService.prototype, "verificationCode", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "setCodeExpired", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "onVerificationCodeChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "resendTimer", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "decrementCodeLeftSeconds", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "setCodeResendForbidden", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "setCodeLeftSeconds", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "onVerificationCodeKeyUp", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "insertMultipleValues", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "onVerificationCodeKeyDown", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "onVerificationCodePaste", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "removePreviousDigit", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "onVerificationCodeArrowPress", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "setCodeInvalid", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "handleResendClick", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], CodeVerificationService.prototype, "initSendVerificationCode", void 0);
CodeVerificationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], CodeVerificationService);
