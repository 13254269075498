import { route } from 'react-router-typesafe-routes/dom';
export const ROUTES = {
    MAIN: route(''),
    PAYMENT: route('payment/:id'),
    SUPPORT: route('support'),
    MESSAGES: route('messages'),
    PERSONAL_INFO: route('personal-info'),
    ACCOUNT_PAYMENTS: route('account-payments'),
    PURCHASE_HISTORY: route('purchase-history'),
};
