var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { action, makeObservable, observable } from 'mobx';
import { useService } from '~/services/service.base';
import { WrapperService } from '~/services/service.wrapper';
import { api } from '~/tools/api/api';
import { EApiUrl } from '~/tools/api/enums';
import { Injectable } from './service.base';
export let SignInDialogService = class SignInDialogService {
    open = false;
    showStep2 = false;
    showStep1 = true;
    showStep3 = false;
    btnDisabled = true;
    showLoading = false;
    userNotRegisteredError = false;
    fullNumber;
    phoneNumber;
    phoneAsPassword = false;
    authService;
    constructor() {
        const wrapperService = useService(WrapperService);
        const { authService } = wrapperService;
        this.authService = authService;
        makeObservable(this);
        const storage = window.sessionStorage.getItem('signInDialog');
        if (storage) {
            this.openFromStorage(storage);
        }
    }
    handleOpen = (e) => {
        e.preventDefault();
        this.open = true;
        this.addToSessionStorage({ step: 1 });
    };
    handleClose = () => {
        this.open = false;
        this.showStep1 = true;
        this.showStep2 = false;
        this.showStep3 = false;
        this.fullNumber = '';
        this.removeFromSessionStorage();
    };
    handleOpenStep2 = () => {
        this.setShowStep1(false);
        this.setShowStep2(true);
        this.addToSessionStorage({ step: 2, data: { fullNumber: this.fullNumber } });
    };
    handleOpenStep1 = (e) => {
        e.preventDefault();
        this.showStep1 = true;
        this.showStep2 = false;
    };
    handleOpenStep3 = async () => {
        const sendResult = await this.sendVerificationCode();
        if (sendResult) {
            this.setShowStep2(false);
            this.setShowStep3(true);
        }
    };
    handlePhoneNumberChange = (isValid, value, selectedCountryData, fullNumber) => {
        this.btnDisabled = !isValid;
        this.fullNumber = fullNumber;
        this.addToSessionStorage({ step: 1, data: { phone: value } });
    };
    sendVerificationCode = async () => {
        this.setUserNotRegisteredError(false);
        let sendResult = false;
        this.setShowLoading(true);
        try {
            let phone = this.fullNumber.replaceAll(/-/ig, '');
            phone = phone.replaceAll(/\s/ig, '');
            const response = await api.post(EApiUrl.requestSmsCode, { phoneNumber: phone });
            if (response.status === 200) {
                sendResult = true;
            }
        }
        catch (error) {
            this.setUserNotRegisteredError(true);
        }
        this.setShowLoading(false);
        return sendResult;
    };
    setShowStep1 = (value) => {
        this.showStep1 = value;
    };
    setShowStep2 = (value) => {
        this.showStep2 = value;
    };
    setShowStep3 = (value) => {
        this.showStep3 = value;
    };
    setShowLoading = (value) => {
        this.showLoading = value;
    };
    setUserNotRegisteredError = (value) => {
        this.userNotRegisteredError = value;
    };
    getAuthToken = async (verificationCode) => {
        let verifyResult = false;
        this.setShowLoading(true);
        try {
            let phone = this.fullNumber.replaceAll(/-/ig, '');
            phone = phone.replaceAll(/\s/ig, '');
            const response = await api.post(EApiUrl.verifySmsCode, { code: verificationCode, phoneNumber: phone });
            if (response.status === 200 && response.data?.token) {
                await this.authService.login(response.data?.token);
                this.handleClose();
                verifyResult = true;
            }
        }
        catch (error) {
        }
        this.setShowLoading(false);
        return verifyResult;
    };
    handlePhoneEyeClick = () => {
        this.phoneAsPassword = !this.phoneAsPassword;
        const phoneInputType = this.phoneAsPassword ? 'password' : 'tel';
        const phoneInput = document.getElementById('sign-in-phone');
        // @ts-ignore
        phoneInput.setAttribute('type', phoneInputType);
    };
    setBtnDisabled = (value) => {
        this.btnDisabled = value;
    };
    onStepKeyUp = (e) => {
        if (e.keyCode === 13 && this.showStep1 && !this.btnDisabled) {
            this.handleOpenStep2();
        }
        else if (e.keyCode === 13 && this.showStep2) {
            this.handleOpenStep3();
        }
    };
    addToSessionStorage = (data) => {
        window.sessionStorage.setItem('signInDialog', JSON.stringify(data));
    };
    openFromStorage = (storage) => {
        const storageData = JSON.parse(storage);
        if (storageData.step) {
            switch (storageData.step) {
                case 1:
                    this.open = true;
                    if (storageData.data?.phone) {
                        this.phoneNumber = storageData.data?.phone;
                    }
                    break;
                case 2:
                    this.open = true;
                    this.setShowStep1(false);
                    this.setShowStep2(true);
                    this.fullNumber = storageData.data?.fullNumber;
                    break;
            }
        }
    };
    removeFromSessionStorage = () => {
        window.sessionStorage.removeItem('signInDialog');
    };
};
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "open", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep2", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep1", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showStep3", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "btnDisabled", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "showLoading", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "userNotRegisteredError", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], SignInDialogService.prototype, "fullNumber", void 0);
__decorate([
    observable,
    __metadata("design:type", String)
], SignInDialogService.prototype, "phoneNumber", void 0);
__decorate([
    observable,
    __metadata("design:type", Boolean)
], SignInDialogService.prototype, "phoneAsPassword", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpen", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleClose", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handleOpenStep3", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "handlePhoneNumberChange", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setShowStep1", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setShowStep2", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setShowStep3", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setShowLoading", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setUserNotRegisteredError", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "getAuthToken", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "setBtnDisabled", void 0);
__decorate([
    action,
    __metadata("design:type", Object)
], SignInDialogService.prototype, "onStepKeyUp", void 0);
SignInDialogService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [])
], SignInDialogService);
