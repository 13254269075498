import { useRef } from 'react';
function convertLengthToRefs(length, initialValue) {
    return Array.from(new Array(length)).map(() => ({
        current: initialValue,
    }));
}
export function useRefs(length, initialValue = null) {
    const refs = useRef(convertLengthToRefs(length, initialValue));
    return refs.current;
}
