import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '~/routes/routes';
import { BackBtn } from '~/views/atoms/back.btn';
import { Support } from '~/views/organisms/support';
import { SupportMenu } from '~/views/molecules/support.menu';
import { SupportMenuItem } from '~/views/enums';
import { SupportMessages } from '~/views/organisms/support.messages';
export const PageSupport = observer(() => {
    const location = useLocation();
    const isSupportPage = useMemo(() => location.pathname === ROUTES.SUPPORT.path, [location.pathname]);
    return (_jsxs("div", { className: "container", children: [_jsx(BackBtn, { path: ROUTES.MAIN.path }), _jsxs("div", { className: "account-wrap", children: [_jsx(SupportMenu, { activeItem: isSupportPage ? SupportMenuItem.support : SupportMenuItem.messages }), isSupportPage ? _jsx(Support, {}) : _jsx(SupportMessages, {})] })] }));
});
