import { createContext, useContext } from 'react';
import { useService } from '~/services/service.base';
import { WrapperService } from '~/services/service.wrapper';
export const AuthContext = createContext(null);
export const useAuth = async () => {
    const wrapperService = useService(WrapperService);
    const { authService } = wrapperService;
    authService.initUser();
};
export const useAuthContext = () => {
    return useContext(AuthContext);
};
